'use strict';

/* exported
menuTransportsCtrl
 */

function menuTransportsCtrl($scope, $location, $rootScope, user) {
  $scope.filters = {};
  $scope.user = user;

  // On init if company is selected display it
  var search = $location.search();

  // On init if city is selected display it
  if (search.city) {
    $scope.filters.city = {_id: search.city};
  }

  // On init if cityB is selected display it
  if (search.cityB) {
    $scope.filters.cityB = {_id: search.cityB};
  }

  if (search.company) {
    $scope.filters.company = {_id: search.company};
  }

  if (search.supplier) {
    $scope.filters.supplier = {_id: search.supplier};
  }

  // On init if country is selected display it
  if (search.country) {
    $scope.filters.country = {iso2: search.country};
  }

  // On init if type is selected display it
  if (search.type) {
    $scope.filters.type = search.type;
  }

  if (search.isSupplierApi) {
    $scope.filters.isSupplierApi = search.isSupplierApi;
  }

  
  if (search.reference) {
    $scope.filters.reference = search.reference;
  }
  
  $scope.$watch('filters.reference', function(reference) {
    $rootScope.$broadcast('filters.reference', (reference));

    var search = $location.search();
    search.reference = (reference);
    $location.search(search);
  });

  $scope.$watch('filters.country', function(country) {
    $rootScope.$broadcast('filters.country', (country || {}).iso2);

    var search = $location.search();
    search.country = (country || {}).iso2;
    $location.search(search);
  });

  $scope.$watch('filters.company', function(company) {
    $rootScope.$broadcast('filters.company', (company || {})._id);

    var search = $location.search();
    search.company = (company || {})._id;
    $location.search(search);
  });

  $scope.$watch('filters.supplier', function(supplier) {
    $rootScope.$broadcast('filters.supplier', (supplier || {})._id);

    var search = $location.search();
    search.supplier = (supplier || {})._id;
    $location.search(search);
  });

  $scope.$watch('filters.city', function(city) {
    $rootScope.$broadcast('filters.city', (city || {})._id);

    var search = $location.search();
    search.city = (city || {})._id;
    $location.search(search);
  });

  $scope.$watch('filters.cityB', function(cityB) {
    $rootScope.$broadcast('filters.cityB', (cityB || {})._id);

    var search = $location.search();
    search.cityB = (cityB || {})._id;
    $location.search(search);
  });

  $scope.filterByType = function(type) {
    if (type === $scope.filters.type) {
      // Set off filter
      $scope.filters.type = null;
    } else {
      $scope.filters.type = type;
    }

    $rootScope.$broadcast('filters.type', $scope.filters.type);

    var search = $location.search();
    search.type = $scope.filters.type;
    $location.search(search);
  };

  $scope.reset = function() {
    $scope.filters = {};
    $rootScope.$broadcast('resetFilters', $scope.filters);
    $location.search({});
  };
}

/**
 * Created by john on 29/9/16.
 */
'use strict';

/* exported
transportsCtrl
 */

function transportsCtrl($scope, transports, $location, user, moment, appConfig) {
  $scope.transports = transports;
  $scope.filters = {};
  $scope.user = user;
  $scope.appConfig = appConfig;

  /**
   * Paging
   */
  // On init if page is above 1
  var search = $location.search();
  if (search.page && search.page >= 1) {
    $scope.currentPage = search.page;
  } else {
    $scope.currentPage = 1;
  }

  $scope.totalItems = transports.meta.total;
  $scope.pageChanged = function() {
    var limit = 20;
    var offset = ($scope.currentPage - 1) * 20;

    $scope.filters.offset = offset;
    $scope.filters.limit = limit;

    $scope.transports.getList($scope.filters).then(function(transports) {
      $scope.transports = transports;
    });
  };

  $scope.$watch('currentPage', function(page) {
    var search = $location.search();

    if (page > 1) {
      search.page = page;
    } else {
      delete search.page;
    }

    $location.search(search);
  });


  /**
   * Filters
   */

  $scope.$on('filters.reference', function(event, data) {
    $scope.filters.reference = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    $scope.transports.getList($scope.filters).then(function(transports) {
      $scope.currentPage = 1;
      $scope.transports = transports;
      $scope.totalItems = $scope.transports.meta.total;
    });
  });  

  $scope.$on('filters.country', function(event, data) {
    $scope.filters.country = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    $scope.transports.getList($scope.filters).then(function(transports) {
      $scope.currentPage = 1;
      $scope.transports = transports;
      $scope.totalItems = $scope.transports.meta.total;
    });
  });

  $scope.$on('filters.city', function(event, data) {
    $scope.filters.city = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    $scope.transports.getList($scope.filters).then(function(transports) {
      $scope.currentPage = 1;
      $scope.transports = transports;
      $scope.totalItems = $scope.transports.meta.total;
    });
  });

  $scope.$on('filters.cityB', function(event, data) {
    $scope.filters.cityB = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    $scope.transports.getList($scope.filters).then(function(transports) {
      $scope.currentPage = 1;
      $scope.transports = transports;
      $scope.totalItems = $scope.transports.meta.total;
    });
  });

  $scope.$on('filters.company', function(event, data) {
    $scope.filters.company = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    $scope.transports.getList($scope.filters).then(function(transports) {
      $scope.currentPage = 1;
      $scope.transports = transports;
      $scope.totalItems = $scope.lines && $scope.lines.meta.total;
    });
  });

  $scope.$on('filters.supplier', function(event, data) {
    $scope.filters.supplier = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    $scope.transports.getList($scope.filters).then(function(transports) {
      $scope.currentPage = 1;
      $scope.transports = transports;
      $scope.totalItems = $scope.transports.meta.total;
    });
  });

  $scope.$on('filters.type', function(event, data) {
    $scope.filters.type = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    $scope.transports.getList($scope.filters).then(function(transports) {
      $scope.currentPage = 1;
      $scope.transports = transports;
      $scope.totalItems = $scope.transports.meta.total;
    });
  });

  $scope.$on('resetFilters', function(event, filters) {
    $scope.filters = Object.assign({}, filters);
    $scope.currentPage = 1;

    $scope.transports.getList(filters).then(function(transports) {
      $scope.transports = transports;
      $scope.totalItems = $scope.transports.meta.total;
    });
  });

  $scope.lineTypeHasTwoIcons = function (lineType) {
    return ['van-ferry', 'car-ferry', 'bus-ferry'].includes(lineType);
  };

  $scope.getLineTypeColumnStyle = function (index) {
    return {'display': 'flex', 'flex-direction': 'column', 'align-items': 'center', 'border-width': '0px', 'border-top-width': index === 0 ? '0px': '1px', 'padding-top': '6px'};
  }

}

'use strict';

/* exported
 menuTransportCtrl
 */

function menuTransportCtrl($scope, user, transportEditService) {
  $scope.transportEditService = transportEditService;
  $scope.user = user;
  $scope.getPreviewLink = function() {
    return transportEditService.getTransportInConsumersUrl();
  };
}

'use strict';

/* exported
transportCtrl
 */

function transportCtrl($scope, user, transportEditService) {
  $scope.transportEditService = transportEditService;
  $scope.user = user;
  $scope.rideRuleLevel = {type: 'Transport'};
}

'use strict';

/* exported
 transportEditProvider
 */

function transportEditProvider() {
    this.activeTab = 0;
    this.transport = {};

    this.$get = ['InventoryRestangular', '$rootScope', 'appConfig', 'RideRulesService', function(InventoryRestangular, $rootScope, appConfig, RideRulesService) {
        let transportEditService = {
            rulesAttribute: [],
            ruleCategories: RideRulesService.getRuleCategories(),
            filters: {
                category: RideRulesService.defaultRuleCategory
            },
        };


        transportEditService.setActiveTab = function(index) {
            this.activeTab = index;
        };

        function handleError(err) {
          var message = '';
          if (_.isArray(err)) {
            message = err.join('<br>');
          } else {
            message = _.get(err, 'data.message') || err.message || '';
          }
          console.error('failed operation with error: \n' + message.replace(/<br>/g, '\n'));
          if(message !== 'Closed Modal') {
            $rootScope.$emit('notify', {type: 'error', title: 'Error', message: message});
          }
        }

        transportEditService.refreshRules = function(transportReference = this.transport.reference) {
            transportEditService.rulesAttribute = [];
            transportEditService.ruleCategories = RideRulesService.getRuleCategories();
            transportEditService.filters = {
                category: RideRulesService.defaultRuleCategory
            };

            return InventoryRestangular.all('/ride-rules/transport/' + transportReference)
                .getList()
                .then(function (rulesAttribute) {
                    transportEditService.rulesAttribute = RideRulesService.sortRulesAttribute(rulesAttribute);
                    transportEditService.ruleCategories = RideRulesService.getRuleCategories(transportEditService.rulesAttribute);
                    transportEditService.filters.category = transportEditService.ruleCategories[0];
                });
        }

        transportEditService.retrieveServiceForTransport = function(transportReference) {
            var self = this;
            return InventoryRestangular
                .one('transport', transportReference)
                .get()
                .then(function(transport) {
                    self.transport = transport;
                    self.refreshRules(transportReference);
                    return self;
                });
        };

        transportEditService.getTransportInConsumersUrl = function () {
            if (this.transport._id) {
                const fromCity = this.transport.fromCity.slug;
                const toCity = this.transport.toCity.slug;
                const country = this.transport.toCity.countrySlug;
                const slug = this.transport.reference;
                const url = `${appConfig.frontendBrowserUrl}/routes/${country}/${fromCity}-to-${toCity}/${slug}`;

                return url;
            } else {
                return null;
            }
        };

        return transportEditService;
    }];
}
'use strict';

/* exported
ruleModalCtrl
 */
/**
 * 
 * @param  attribute | attribute object includes the rules array
 * @param  index | index of the rule in the rules array, -1 on add action
 * @param  action | add or edit action
 */
function ruleModalCtrl($scope, InventoryRestangular, $uibModalInstance, rideRulesService, supplierCurrency, supplierSource, ruleResource, ruleResourceType, attribute,  index, action, RIDE_RULE_LEVELS, $rootScope) {

   $scope.ruleIndex = index;
   $scope.rideRuleLevels = RIDE_RULE_LEVELS;
   $scope.action = action;
   $scope.level = ruleResourceType.type;
   $scope.supplierName = $scope.level === RIDE_RULE_LEVELS.TRANSPORT ? ruleResource.supplier.name : ruleResource.name;
   $scope.ruleResourceName = '';
   $scope.ruleResourceDescription = $scope.level === $scope.rideRuleLevels.TRANSPORT ? `${$scope.supplierName} > ${ruleResource.operator.name} > ${ruleResource.vehicleType} > ${ruleResource.vehicleClass}` : '';
   $scope.buttonText = $scope.action === 'edit' ? 'Save changes' : 'Add Rule';
   $scope.ruleName = attribute.displayName;
   $scope.attributeName = attribute.attributeName;
   $scope.attributeSlug = attribute.slug;
   $scope.rules = attribute.rules;
   $scope.luggagePolicyType = '';
   $scope.selectedOption = 'refundable';
   $scope.flexibleCancellation = false;
   $scope.fromCity = '';
   $scope.toCity = '';
   $scope.vehiclesType = [];
   $scope.vehiclesClass = [];
   $scope.multiCompanies = [];
   $scope.voucherSettings = {
    isPrintedVoucherRequire: false,
    showPriceOnVoucher: false,
    showCostOnVoucher: false,
    showPriceInSupplierCurrencyOnVoucher: false,
    showCostInSupplierCurrencyOnVoucher: false,
    showNumberOfTicketsOnVoucher: false,
    isDisplayLogoAsSupplier: false,
    voucherRemark: '',
    voucherAlert: {
      title: '',
      text: '',
    }
  };
  $scope.inputErrorMessage = '';
  $scope.bookingFee = {
    price: {amountInSupplierCurrency: $scope.rules[$scope.ruleIndex]? $scope.rules[$scope.ruleIndex].attributes[0].value.amount : undefined,
            currency: supplierCurrency? supplierCurrency : 'EUR'},
    isPerPassenger: $scope.rules[$scope.ruleIndex]? $scope.rules[$scope.ruleIndex].attributes[0].value.isPerPassenger : false,
  };

  if($scope.level === $scope.rideRuleLevels.OPERATOR) {
    $scope.fromCity = _.get(attribute, `rules[${$scope.ruleIndex}].conditions.routes[0].fromCityId`);
    $scope.toCity = _.get(attribute, `rules[${$scope.ruleIndex}].conditions.routes[0].toCityId`);
    $scope.vehiclesType = _.get(attribute, `rules[${$scope.ruleIndex}].conditions.vehicleType`, 'All Types');
    $scope.vehiclesClass = _.get(attribute, `rules[${$scope.ruleIndex}].conditions.vehicleClass`, 'All Classes');
  }

  const values = _.get($scope, `rules[${$scope.ruleIndex}].attributes[0].value`, undefined);

  if ($scope.attributeSlug === 'pictures' && values) {
    $scope.rules[$scope.ruleIndex].attributes[0].value = $scope.rules[$scope.ruleIndex].attributes[0].value.map((el) => {
      return Object.assign(el, {
        type: 'picture'
      });
    });
  }

  if ($scope.attributeSlug === 'voucher-remark' && values) {
    // For now we have only one value for voucher remark, check if we need to add more values in future
    // $scope.voucherSettings = angular.extend({}, $scope.voucherSettings, values);
    $scope.voucherSettings.voucherRemark = values;
  }

  if ($scope.attributeSlug === 'voucher-alert' && values) {
    $scope.voucherSettings.voucherAlert = values;
  }

  if ($scope.level === $scope.rideRuleLevels.TRANSPORT) {
    $scope.ruleResourceName = `${ruleResource.fromCity.name} - ${ruleResource.toCity.name}`;
    $scope.fromCity = ruleResource.fromCity;
    $scope.toCity = ruleResource.toCity;
  } else if ($scope.level === $scope.rideRuleLevels.OPERATOR || $scope.level === $scope.rideRuleLevels.SUPPLIER) {
    $scope.ruleResourceName = ruleResource.name;
  }

   const source = _.get($scope, `rules[$scope.ruleIndex].source`, '');
   if($scope.level === $scope.rideRuleLevels.SUPPLIER && source.toLowerCase() === $scope.rideRuleLevels.SUPPLIER.toLowerCase()) {
     if($scope.rules[$scope.ruleIndex].conditions && $scope.rules[$scope.ruleIndex].conditions.operator) {
      $scope.multiCompanies = $scope.rules[$scope.ruleIndex].conditions.operator;
     }
   }

  $scope.updateSelectedOption = function() {
    $scope.selectedOption = $scope.selectedOption === 'refundable' ? 'not-refundable' : 'refundable';
    $scope.flexibleCancellation = $scope.selectedOption === 'refundable' ? false : true;
  };


   $scope.luggagePolicyType = 'openText';
   $scope.featuresAndKeyDetails = {
    keyDetails: attribute && attribute.rules && attribute.rules[$scope.ruleIndex] && attribute.rules[$scope.ruleIndex].attributes && attribute.rules[$scope.ruleIndex].attributes[0] && attribute.rules[$scope.ruleIndex].attributes[0].value,
    features: []
   };

   if($scope.attributeSlug === 'cancellation-policy') {
    const isFlexibleCancellation = _.get($scope, `rules[${$scope.ruleIndex}].attributes[0].value.isFlexibleCancellation`, false);
    const cancellations = _.get($scope, `rules[${$scope.ruleIndex}].attributes[0].value.cancellations`, []);
    if (isFlexibleCancellation) {
        $scope.flexibleCancellation = true;
        $scope.selectedOption = 'not-refundable';
        if(cancellations.length === 0) {
          $scope.rules[$scope.ruleIndex].attributes[0].value.cancellations= [{
            timeBefore: null,
            unit: 'hours',
            fee: null,
        }];
        }
    }else if (cancellations.length === 0) {
        $scope.selectedOption = 'not-refundable';
        $scope.flexibleCancellation = false;
        if($scope.action === 'add' || !$scope.rules || $scope.rules.length === 0){
          $scope.rules.push({attributes: [{value: {cancellations: []}}], conditions: {}});
          $scope.ruleIndex = $scope.rules.length - 1;
        }

        if(!$scope.rules[$scope.ruleIndex].attributes || $scope.rules[$scope.ruleIndex].attributes.length === 0){
          $scope.rules[$scope.ruleIndex].attributes= [{
            value: {
              cancellations: []
            }
          }];
        }
        if(!$scope.rules[$scope.ruleIndex].attributes[0].value || !$scope.rules[$scope.ruleIndex].attributes[0].value.cancellations){
          $scope.rules[$scope.ruleIndex].attributes[0].value= {
            cancellations: []
          };
        }
        if(!$scope.rules[$scope.ruleIndex].attributes[0].value.cancellations ){
          $scope.rules[$scope.ruleIndex].attributes[0].value.cancellations= [];
        }

        $scope.rules[$scope.ruleIndex].attributes[0].value.cancellations= [{
            timeBefore: null,
            unit: 'hours',
            fee: null,
        }];
    }
  }



   angular.element(document).ready(function () {
    var toolbar = document.querySelector('.ta-toolbar[name="toolbar"]');
    if (toolbar) {
        var buttonsToHide = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'pre', 'quote', 'strikeThrough', 'ul', 'ol', 'redo', 'undo', 'clear', 'justifyLeft', 'justifyRight', 'justifyCenter', 'justifyFull', 'indent', 'outdent', 'html', 'insertImage', 'insertVideo' ]; // Names of buttons to hide
        buttonsToHide.forEach(function(buttonName) {
            var button = toolbar.querySelector('button[name="' + buttonName + '"]');
            if (button) {
                button.style.display = 'none';
            }
        });
    }
    var wordCountDiv = document.querySelector('.ta-toolbar[name="toolbar"] #toolbarWC');
    if (wordCountDiv) {
        wordCountDiv.style.display = 'none';
    }

    var charCountDiv = document.querySelector('.ta-toolbar[name="toolbar"] #toolbarCC');
    if (charCountDiv) {
        charCountDiv.style.display = 'none';
    }
  });

   $scope.setLuggageTypeToCustom = function() {
    //For now we are not using this, waiting for the API to be ready
    // $scope.luggagePolicyType = 'customLuggage';
   };
   $scope.setLuggageTypeToOpenText = function() {
    $scope.luggagePolicyType = 'openText';
   };

   $scope.addNewCancellation = function () {
    $scope.rules[$scope.ruleIndex].attributes[0].value.cancellations.push({
      timeBefore: null,
      unit: 'hours',
      fee: null,
    });
   };

   $scope.removeRuleValueArrayItem = function (index) {
    $scope.rules[$scope.ruleIndex].attributes[0].value.cancellations.splice(index, 1);
   };

   $scope.getTitle = function () {
    var capitalizedAction = this.action.charAt(0).toUpperCase() + this.action.slice(1);
    return capitalizedAction + ' ' + this.level + ' Rule';
   };

   $scope.set = function() {
    const errorMessage = ['Form has errors'];
    var isInvalid = this.addStationForm.$invalid;

    let vehicleClass = $scope.vehiclesClass;
    let vehicleType = $scope.vehiclesType;
    let operator = $scope.rules && $scope.rules[$scope.ruleIndex] && $scope.rules[$scope.ruleIndex].conditions && $scope.rules[$scope.ruleIndex].conditions.operator && $scope.rules[$scope.ruleIndex].conditions.operator.map(operator => operator._id);
    if($scope.level === $scope.rideRuleLevels.TRANSPORT){
      if((!vehicleClass || vehicleClass.length === 0) && ruleResource.vehicleClass) {
        vehicleClass = [ruleResource.vehicleClass];
      }

      if((!vehicleType || vehicleType.length === 0) && ruleResource.vehicleType) {
        vehicleType = [ruleResource.vehicleType];
      }

      operator = [ruleResource.operatorId || ruleResource.operator._id];
    }

    if($scope.level === $scope.rideRuleLevels.OPERATOR){
      if(Array.isArray($scope.vehiclesType) && $scope.vehiclesType.length > 0 && $scope.vehiclesType[0].key){
        vehicleType = $scope.vehiclesType.map((vehicle) => {
          if(vehicle.key){
            return vehicle.key;
          }
        });
      }
      if(Array.isArray($scope.vehiclesClass) && $scope.vehiclesClass.length > 0 && $scope.vehiclesClass[0].key){
        vehicleClass = $scope.vehiclesClass.map((vehicle) => {
          if(vehicle.key){
            return vehicle.key;
          }
        });
      }

      operator = [_.get(ruleResource, 'bookawayData.companyId')];
    }

    if ($scope.level === $scope.rideRuleLevels.SUPPLIER && $scope.multiCompanies && $scope.multiCompanies.length > 0) {
      operator = $scope.multiCompanies.map(opr => opr._id);
    }

    let supplier;
    if(action === 'edit'){
      supplier = $scope.rules[$scope.ruleIndex].conditions.supplier._id;
    } else if (action === 'add'){
      if($scope.level === RIDE_RULE_LEVELS.OPERATOR ){
        supplier = supplierSource.supplierId? supplierSource.supplierId : supplierSource.supplier._id;
      }else {
        supplier = ruleResource.supplierId? ruleResource.supplierId : ruleResource.supplier._id;
      }
    }

    const fromCityId = _.get($scope, 'fromCity._id');
    const toCityId = _.get($scope, 'toCity._id');

    const body = {
      conditions : {
        supplier,
        operator: Array.isArray(operator) && operator.length > 0 && !operator.includes(null) && !operator.includes(undefined) ? operator : undefined,
        routes: ($scope.level === $scope.rideRuleLevels.TRANSPORT || $scope.level === $scope.rideRuleLevels.OPERATOR) && fromCityId && toCityId ? [
          {
            id: `${fromCityId}-${toCityId}`,
            slug: `${fromCityId}-${toCityId}`,
            fromCityId,
            toCityId,
          },
        ] : undefined,
        vehicleClass: Array.isArray(vehicleClass) && vehicleClass.length > 0 ? vehicleClass : undefined,
        vehicleType: Array.isArray(vehicleType) && vehicleType.length > 0 ? vehicleType : undefined,
      },
      attributes : [
        {
        attributeName : attribute.attributeName,
        },
      ],
    };

    switch($scope.attributeSlug) {
      case 'booking-fee':
        body.attributes[0].value = {amount: $scope.bookingFee.price.amountInSupplierCurrency};
        body.attributes[0].value.isPerPassenger = $scope.bookingFee.isPerPassenger;
        break;
      case 'special-notes':
      case 'stops':
      case 'seat-type':
      case 'pickup-dropoff-points':
      case 'phone-required':
      case 'luggage':
      case 'passenger-alert':
      case 'pictures':
      case 'custom-services':
        body.attributes[0].value = $scope.rules[$scope.ruleIndex].attributes[0].value;
        break;
      case 'minimum-passengers':
        body.attributes[0].value = $scope.rules[$scope.ruleIndex].attributes[0].value.toString();
        break;
      case 'markup':
        body.attributes[0].value = Number($scope.rules[$scope.ruleIndex].attributes[0].value);
        break;
      case 'commission-from-api':
        body.attributes[0].value = Number($scope.rules[$scope.ruleIndex].attributes[0].value);
        break;
      case 'cancellation-policy':
        if(!body.attributes[0].value){
          body.attributes[0].value = {};
        }
        if(!body.attributes[0].value.cancellations){
          body.attributes[0].value.cancellations = [];
        }
        if($scope.selectedOption === 'not-refundable') {
          body.attributes[0].value.isFlexibleCancellation = $scope.flexibleCancellation;
        }else{
          $scope.rules[$scope.ruleIndex].attributes[0].value.cancellations.forEach((cancellation) => {
            if(cancellation.timeBefore && cancellation.fee){
              body.attributes[0].value.cancellations.push(cancellation);
            }
          });
        }
        break;
      case 'amenities-and-facilities':
        const value = [];
        $scope.featuresAndKeyDetails.keyDetails.forEach((amenity) => {
          if(amenity.key){
            if(typeof amenity.key === 'string'){
              value.push({key: amenity.key, comment: amenity.comment || undefined});
            }else{
              value.push({key: amenity.key._id, comment: amenity.comment || undefined});
            }
          }else if(amenity._id){
            value.push({key: amenity._id, comment: amenity.comment || undefined});
          }
        });
        body.attributes[0].value = value;
        break;
      case 'voucher-remark':
        // For now we are not using this, waiting for the API to be ready supporting all voucher settings,
        // body.attributes[0].value = $scope.voucherSettings;
        body.attributes[0].value = $scope.voucherSettings.voucherRemark;
        break;
      case 'voucher-alert':
        if (!!$scope.voucherSettings.voucherAlert.title !== !!$scope.voucherSettings.voucherAlert.text) {
          $scope.inputErrorMessage = 'Voucher alert should contain both title and text';
          isInvalid = true;
          return false;
        }
        body.attributes[0].value = $scope.voucherSettings.voucherAlert;
        break;
      default:
        break;
    }


    const validations = rideRulesService.validateRideRuleAttribute(body.attributes[0].value, attribute.validation);

    if(!validations) {
      errorMessage.push('Invalid value');
      isInvalid = true;
    }

    if (action === 'edit') {
      InventoryRestangular.restangularizeElement(null, body, `/ride-rules/${$scope.rules[$scope.ruleIndex]._id}`).put()
        .then(function (res) {
          $rootScope.$emit('notify', {type: 'success', title: 'Success', message: 'The rule was saved successfully'});
          $uibModalInstance.close({action: 'edit', rule: res});
        })
        .catch(function (error) {
          // Show the error message to the user.
          $scope.errorMessage = error.data.error;
        });
    } else if (action === 'add'){
      InventoryRestangular.restangularizeElement(null, body, '/ride-rules').post()
        .then(function (res) {
          $rootScope.$emit('notify', {type: 'success', title: 'Success', message: 'The rule was added successfully'});
          $uibModalInstance.close({action: 'add', rule: res});
        })
        .catch(function (error) {
          // Show the error message to the user.
          $scope.errorMessage = error.data.error;
        });
    }


   };

  $scope.flexibleCancellationChange = function() {
    $scope.flexibleCancellation = !$scope.flexibleCancellation;
  };

  $scope.cancel = function () {
    // Remove empty cancellation rules before closing modal
    if($scope.attributeSlug === 'cancellation-policy' && $scope.rules[$scope.ruleIndex].attributes[0].value.cancellations) {
      $scope.rules[$scope.ruleIndex].attributes[0].value.cancellations.forEach((cancellation) => {
        if(!cancellation.timeBefore || !cancellation.fee){
          $scope.removeRuleValueArrayItem($scope.rules[$scope.ruleIndex].attributes[0].value.cancellations.indexOf(cancellation));
        }
      });
    }
    $uibModalInstance.dismiss('cancel');
  };
}
